import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import HomePageView from "../components/home";

// markup
const IndexPage = ({ data }: any) => {
  return (
    <Layout meta={data.site.siteMetadata} title="Home" link={"/"}>
      <main className="">
        <div className="mb-6"></div>
        <HomePageView />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;

export default IndexPage;
