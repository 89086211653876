import * as React from "react";

type Props = {
  icon: string;
  size: number;
  children?: React.ReactNode;
  color?: string;
};

const Icon = ({ icon = "app", size = 4, color = "currentColor" }: Props) => {
  const sizeClass = `inline-block h-${size} w-${size}`;
  if (icon === "github") {
    return (
      <svg
        className={` ${sizeClass} inline-block  `}
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  if (icon === "loading") {
    return (
      <svg
        className={` ${sizeClass} inline-block animate-spin  `}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M11 3c-1.613 0-3.122.437-4.432 1.185l1.65 2.445-6.702-.378 2.226-6.252 1.703 2.522c1.633-.959 3.525-1.522 5.555-1.522 4.406 0 8.197 2.598 9.953 6.34l-1.642 1.215c-1.355-3.258-4.569-5.555-8.311-5.555zm13 12.486l-2.375-6.157-5.307 3.925 3.389.984c-.982 3.811-4.396 6.651-8.488 6.75l.891 1.955c4.609-.461 8.373-3.774 9.521-8.146l2.369.689zm-18.117 3.906c-2.344-1.625-3.883-4.33-3.883-7.392 0-1.314.29-2.56.799-3.687l-2.108-.12c-.439 1.188-.691 2.467-.691 3.807 0 3.831 1.965 7.192 4.936 9.158l-1.524 2.842 6.516-1.044-2.735-6.006-1.31 2.442z" />
      </svg>
    );
  }

  if (icon === "eraser") {
    return (
      <svg
        className={` ${sizeClass} inline-block  `}
        xmlns="http://www.w3.org/2000/svg"
        fill={`${color}`}
        viewBox="0 0 24 24"
      >
        <path d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm.456-11.429l-4.528 4.528 5.658 5.659 4.527-4.53-5.657-5.657z" />
      </svg>
    );
  }

  if (icon === "app") {
    return (
      <svg
        className={` ${sizeClass} inline-block    `}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 25"
      >
        <g
          id="lidalogo"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group-2" transform="translate(0.223541, 0.800000)">
            <path
              d="M20.8954758,1.17770809 L11.0076983,16.9387808 C10.8902975,17.1259171 10.9468293,17.3727932 11.1339656,17.490194 C11.1368435,17.4919994 11.1397443,17.4937682 11.1426672,17.4954997 L21.0304448,23.3529975 C21.2205114,23.4655926 21.4658671,23.4027895 21.5784621,23.2127229 C21.6150245,23.1510037 21.6343161,23.0805876 21.6343161,23.0088515 L21.6343161,1.39028102 C21.6343161,1.16936713 21.45523,0.990281025 21.2343161,0.990281025 C21.0966407,0.990281025 20.968641,1.0610831 20.8954758,1.17770809 Z"
              id="Path"
              fill="#1E15E0"
              transform="translate(16.211977, 11.855365) scale(-1, 1) translate(-16.211977, -11.855365) "
            ></path>
            <path
              d="M8.6,13.7711202 L0.88473459,13.7711202 C0.66382069,13.7711202 0.48473459,13.9502063 0.48473459,14.1711202 C0.48473459,14.2683879 0.52017656,14.3623228 0.584428593,14.435348 L8.299694,23.2040727 C8.44562296,23.3699271 8.69837334,23.3860799 8.86422776,23.2401509 C8.95053698,23.1642107 9,23.0548066 9,22.9398449 L9,14.1711202 C9,13.9502063 8.8209139,13.7711202 8.6,13.7711202 Z"
              id="Path"
              fill="#4F46E4"
            ></path>
            <path
              d="M9,5.99487663 L9,11.467418 C9,11.6883319 8.8209139,11.867418 8.6,11.867418 L4.19493901,11.867418 C3.97402511,11.867418 3.79493901,11.6883319 3.79493901,11.467418 C3.79493901,11.3752213 3.82678804,11.2858551 3.88509757,11.2144392 L5.12232365,9.69911904 L5.12232365,9.69911904 C4.75371343,8.04721342 3.81738202,6.57692234 2.4763755,5.54427179 L2,5.17743583 L2,5.17743583 L3.45169545,5.17743583 C4.93523315,5.17743583 6.32206214,5.91366511 7.15325083,7.14248953 L7.15325083,7.14248953 L7.15325083,7.14248953 L8.28943776,5.74278321 C8.42866511,5.57126442 8.68057463,5.54508704 8.85209342,5.68431439 C8.94566292,5.76026778 9,5.87436038 9,5.99487663 Z"
              id="Path-2"
              fill="#E0BC03"
            ></path>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={` ${sizeClass} inline-block  `}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M1 3.488c0-1.926 4.656-3.488 10-3.488 5.345 0 10 1.562 10 3.488s-4.655 3.487-10 3.487c-5.344 0-10-1.561-10-3.487zm10 9.158c5.345 0 10-1.562 10-3.487v-2.44c-2.418 1.738-7.005 2.256-10 2.256-3.006 0-7.588-.523-10-2.256v2.44c0 1.926 4.656 3.487 10 3.487zm0 5.665c.34 0 .678-.007 1.011-.019.045-1.407.537-2.7 1.342-3.745-.839.067-1.643.1-2.353.1-3.006 0-7.588-.523-10-2.256v2.434c0 1.925 4.656 3.486 10 3.486zm1.254 1.97c-.438.02-.861.03-1.254.03-2.995 0-7.582-.518-10-2.256v2.458c0 1.925 4.656 3.487 10 3.487 1.284 0 2.526-.092 3.676-.256-1.155-.844-2.02-2.055-2.422-3.463zm10.746-1.781c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.166-1.289l-2.063.557.916-1.925-1.387.392-1.466 3.034 1.739-.472-1.177 2.545 3.438-4.131z" />
    </svg>
  );
};
export default Icon;
