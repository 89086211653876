import * as React from "react";
import HeroView from "./hero";

const HomePageView = ({ data }: any) => {
  return (
    <div>
      <HeroView />
    </div>
  );
};

export default HomePageView;
