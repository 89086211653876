import * as React from "react";
import Icon from "./icons";

const Footer = () => {
  return (
    <div className="mt-4 text-primary p-3  mb-6 border-t border-secondary ">
      <div>
        <span className="text-accent inline-block mr-1">
          {" "}
          <Icon icon="app" size={5} />
        </span>{" "}
        Indie ML Labs (c) 2022
      </div>
    </div>
  );
};
export default Footer;
